import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const FolderKindDefaultScaleSmall = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8.66683 4.66667L7.92313 3.17928C7.7091 2.7512 7.60207 2.53715 7.44241 2.38078C7.30122 2.24249 7.13104 2.13732 6.94421 2.07287C6.73294 2 6.49363 2 6.01502 2H3.46683C2.72009 2 2.34672 2 2.06151 2.14532C1.81063 2.27316 1.60665 2.47713 1.47882 2.72801C1.3335 3.01323 1.3335 3.3866 1.3335 4.13333V4.66667M1.3335 4.66667V10.8C1.3335 11.9201 1.3335 12.4802 1.55148 12.908C1.74323 13.2843 2.04919 13.5903 2.42552 13.782C2.85334 14 3.41339 14 4.5335 14H11.4668C12.5869 14 13.147 14 13.5748 13.782C13.9511 13.5903 14.2571 13.2843 14.4488 12.908C14.6668 12.4802 14.6668 11.9201 14.6668 10.8V7.86667C14.6668 6.74656 14.6668 6.18651 14.4488 5.75869C14.2571 5.38236 13.9511 5.0764 13.5748 4.88465C13.147 4.66667 12.5869 4.66667 11.4668 4.66667H1.3335Z"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Folder'
);
export default FolderKindDefaultScaleSmall;
