import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const LupaScaleSmallKindHeartAppearanceFilled = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14.204 1.13819C14.0574 0.988711 13.8833 0.870133 13.6917 0.78923C13.5002 0.708328 13.2948 0.666687 13.0874 0.666687C12.88 0.666687 12.6747 0.708328 12.4831 0.78923C12.2915 0.870133 12.1175 0.988711 11.9709 1.13819L11.6666 1.44827L11.3623 1.13819C11.0662 0.836393 10.6645 0.666843 10.2457 0.666843C9.82695 0.666843 9.4253 0.836393 9.12917 1.13819C8.83303 1.43999 8.66667 1.84932 8.66667 2.27613C8.66667 2.70294 8.83303 3.11227 9.12917 3.41407L11.6666 6.00002L14.204 3.41407C14.3507 3.26466 14.467 3.08726 14.5464 2.89201C14.6258 2.69676 14.6667 2.48748 14.6667 2.27613C14.6667 2.06478 14.6258 1.8555 14.5464 1.66025C14.467 1.465 14.3507 1.2876 14.204 1.13819Z"
                fill="var(--bloko-icon-color, var(--bloko-icon-color-default))"
            />
            <path
                d="M6 2.16804C3.6997 2.7601 2 4.84823 2 7.33335C2 10.2789 4.38781 12.6667 7.33333 12.6667C9.81845 12.6667 11.9066 10.967 12.4986 8.66669M14 14L11.1 11.1M14.204 1.13819C14.0574 0.988711 13.8833 0.870133 13.6917 0.78923C13.5002 0.708328 13.2948 0.666687 13.0874 0.666687C12.88 0.666687 12.6747 0.708328 12.4831 0.78923C12.2915 0.870133 12.1175 0.988711 11.9709 1.13819L11.6666 1.44827L11.3623 1.13819C11.0662 0.836393 10.6645 0.666843 10.2457 0.666843C9.82695 0.666843 9.42531 0.836393 9.12917 1.13819C8.83303 1.43999 8.66667 1.84932 8.66667 2.27613C8.66667 2.70294 8.83303 3.11227 9.12917 3.41407L11.6666 6.00002L14.204 3.41407C14.3507 3.26466 14.467 3.08726 14.5464 2.89201C14.6258 2.69676 14.6667 2.48748 14.6667 2.27613C14.6667 2.06478 14.6258 1.8555 14.5464 1.66025C14.467 1.465 14.3507 1.2876 14.204 1.13819Z"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Lupa'
);
export default LupaScaleSmallKindHeartAppearanceFilled;
