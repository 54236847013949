import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const EmployersRatingScaleMediumKindColor = hoc(
    (props: IconProps) => (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <circle cx={12} cy={12} r={12} fill="white" />
                <path
                    d="M6.81055 15.9731C6.81055 15.5905 7.12074 15.2803 7.50337 15.2803H9.29913C9.68176 15.2803 9.99195 15.5905 9.99195 15.9731V18.179H7.50337C7.12074 18.179 6.81055 17.8688 6.81055 17.4862V15.9731Z"
                    stroke="#9547CC"
                    strokeWidth={1.3379}
                />
                <rect
                    x={10.2822}
                    y={13.1621}
                    width={3.18425}
                    height={5.01703}
                    rx={0.692822}
                    stroke="#9547CC"
                    strokeWidth={1.3379}
                />
                <path
                    d="M13.7559 11.7368C13.7559 11.3541 14.0661 11.0439 14.4487 11.0439H16.3544C16.7371 11.0439 17.0473 11.3541 17.0473 11.7368V17.4865C17.0473 17.8691 16.7371 18.1793 16.3544 18.1793H13.7559V11.7368Z"
                    stroke="#9547CC"
                    strokeWidth={1.3379}
                />
                <path
                    d="M14.5108 5.9498L14.5108 5.94981L14.5142 5.94748C14.8416 5.71869 15.2485 5.62496 15.6469 5.68828C16.0447 5.75149 16.399 5.96556 16.6355 6.28139C17.1078 6.9223 16.9638 7.81786 16.291 8.28943C15.9636 8.51801 15.5569 8.61163 15.1586 8.54834C14.7607 8.48511 14.4062 8.27089 14.1698 7.95486C14.0565 7.80172 13.9757 7.62837 13.9315 7.44502C13.8873 7.26127 13.8807 7.07078 13.912 6.88457C13.9434 6.69835 14.0122 6.51974 14.1149 6.35924C14.2175 6.19871 14.352 6.05938 14.5108 5.9498Z"
                    stroke="#9547CC"
                    strokeWidth={1.3379}
                />
            </g>
        </svg>
    ),
    'Employers'
);
export default EmployersRatingScaleMediumKindColor;
