import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const EmployersRatingScaleSmallKindColor = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M2.81055 11.9731C2.81055 11.5905 3.12074 11.2803 3.50337 11.2803H5.29913C5.68176 11.2803 5.99195 11.5905 5.99195 11.9731V14.179H3.50337C3.12074 14.179 2.81055 13.8688 2.81055 13.4862V11.9731Z"
                stroke="#9547CC"
                strokeWidth={1.3379}
            />
            <rect
                x={6.28223}
                y={9.16211}
                width={3.18425}
                height={5.01703}
                rx={0.692822}
                stroke="#9547CC"
                strokeWidth={1.3379}
            />
            <path
                d="M9.75586 7.73677C9.75586 7.35414 10.0661 7.04395 10.4487 7.04395H12.3544C12.7371 7.04395 13.0473 7.35414 13.0473 7.73677V13.4865C13.0473 13.8691 12.7371 14.1793 12.3544 14.1793H9.75586V7.73677Z"
                stroke="#9547CC"
                strokeWidth={1.3379}
            />
            <path
                d="M10.5108 1.9498L10.5108 1.94981L10.5142 1.94748C10.8416 1.71869 11.2485 1.62496 11.6469 1.68828C12.0447 1.75149 12.399 1.96556 12.6355 2.28139C13.1078 2.9223 12.9638 3.81786 12.291 4.28943C11.9636 4.51801 11.5569 4.61163 11.1586 4.54834C10.7607 4.48511 10.4062 4.27089 10.1698 3.95486C10.0565 3.80172 9.97567 3.62837 9.93152 3.44502C9.88727 3.26127 9.88067 3.07078 9.91205 2.88457C9.94343 2.69835 10.0122 2.51974 10.1149 2.35924C10.2175 2.19871 10.352 2.05938 10.5108 1.9498Z"
                stroke="#9547CC"
                strokeWidth={1.3379}
            />
        </svg>
    ),
    'Employers'
);
export default EmployersRatingScaleSmallKindColor;
