/* eslint camelcase:0 */
(function () {
    const mergeVersions = (userAgentRules, minimalVersionsOverride) => {
        if (minimalVersionsOverride) {
            return Object.keys(userAgentRules)
                .map((browser) => {
                    const browserProperties = userAgentRules[browser];
                    return {
                        [browser]: {
                            ...browserProperties,
                            minVersion: minimalVersionsOverride[browser] || browserProperties.minVersion,
                        },
                    };
                })
                .reduce((acc, val) => {
                    return { ...acc, ...val };
                }, {});
        }
        return userAgentRules;
    };

    const supportedBrowsers = {"and_chr":109,"android":109,"chrome":79,"edge":100,"firefox":78,"ios_saf":14.5,"opera":85,"safari":15};
    const userAgentRules = mergeVersions(
        {
            edge: {
                matches: /edge\/(\d+)/,
                minVersion: 12,
            },
            firefox: {
                matches: /firefox\/(\d+)\./,
                minVersion: 40,
            },
            operaMobile: {
                matches: /opera\/(\d+).+opera mobi/,
                minVersion: 12,
            },
            win_safari: {
                matches: /windows nt.+ version\/(\d+)\.[\d.]+ safari/,
                minVersion: 9,
            },
            ios_saf: {
                matches: /cpu(?: iphone)? os (\d+)_[\d_]+ like mac os x/,
                minVersion: 8,
                exclude: (userAgent) => userAgent.indexOf('yabrowser') !== -1,
            },
            android: {
                matches: /android (\d+\.\d)/,
                minVersion: 4.1,
                // https://stackoverflow.com/a/17961266/5014380
                exclude: (userAgent) => {
                    const minimalAndroidBrowserVersion = 537;
                    const webkitVersion = userAgent.match(/webkit\/(\d+(?:\.\d+)?)/)[1];
                    return parseFloat(webkitVersion) > minimalAndroidBrowserVersion;
                },
            },
            chrome: {
                matches: /chrome\/(\d+)\./,
                minVersion: 44,
            },
            safari: {
                matches: /macintosh;.+ version\/(\d+)\.[\d.]+ safari/,
                minVersion: 9,
            },
            // Не поддерживаем
            opera: {
                matches: /presto\/.+ version\/(\d+)/,
                minVersion: 999,
            },
            operaMini: {
                matches: /opera mini\/(\d+)/,
                minVersion: 999,
            },
            // проверка для IE 11, там не всегда есть подстрока «msie»
            // см. https://blogs.msdn.microsoft.com/ieinternals/2013/09/21/internet-explorer-11s-many-user-agent-strings/
            trident: {
                matches: /trident\/(\d+)\./,
                minVersion: 999,
            },
            ie: {
                matches: /msie (\d+)\./,
                minVersion: 999,
            },
            ie_mob: {
                matches: /iemobile\/(\d+)/,
                minVersion: 999,
            },
        },
        supportedBrowsers
    );

    function getUserAgentDetails(userAgent, minimalVersionsOverride) {
        const agent = userAgent.toLowerCase();

        const result = {
            isSupported: true,
            browser: null,
            version: null,
        };

        const rulesToUse = mergeVersions(userAgentRules, minimalVersionsOverride);

        for (const UARule in rulesToUse) {
            const match = agent.match(rulesToUse[UARule].matches);
            if (match) {
                const version = parseFloat(match[1]);
                if (!isNaN(version)) {
                    if (rulesToUse[UARule].exclude && rulesToUse[UARule].exclude(agent)) {
                        /* eslint-disable-next-line no-continue */
                        continue;
                    }
                    result.browser = UARule;
                    result.version = version;
                    if (version < rulesToUse[UARule].minVersion) {
                        result.isSupported = false;
                    }
                    break;
                }
            }
        }
        return result;
    }

    window.bloko = window.bloko || {};
    window.bloko.getUserAgentDetails = getUserAgentDetails;
})();
