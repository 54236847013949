import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const AdjustmentsScaleMedium = hoc(
    (props: IconProps) => (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M5 8L13 8M13 8C13 9.65686 14.3431 11 16 11C17.6569 11 19 9.65685 19 8C19 6.34315 17.6569 5 16 5C14.3431 5 13 6.34315 13 8ZM11 16L19 16M11 16C11 17.6569 9.65685 19 8 19C6.34315 19 5 17.6569 5 16C5 14.3431 6.34315 13 8 13C9.65685 13 11 14.3431 11 16Z"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Adjustments'
);
export default AdjustmentsScaleMedium;
