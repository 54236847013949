import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const ListScaleSmallReducedTrue = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14 9.66667L6 9.66667M14 5.66667L6 5.66667M3.33333 9.66667C3.33333 10.0349 3.03486 10.3333 2.66667 10.3333C2.29848 10.3333 2 10.0349 2 9.66667C2 9.29848 2.29848 9 2.66667 9C3.03486 9 3.33333 9.29848 3.33333 9.66667ZM3.33333 5.66667C3.33333 6.03486 3.03486 6.33333 2.66667 6.33333C2.29848 6.33333 2 6.03486 2 5.66667C2 5.29848 2.29848 5 2.66667 5C3.03486 5 3.33333 5.29848 3.33333 5.66667Z"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'List'
);
export default ListScaleSmallReducedTrue;
