import { ReactNode, memo, FC } from 'react';

import getConversionType from 'bloko/common/getConversionType';

const defaultFormat = (value: string): ReactNode => value;
const defaultFormatValue = (value: number): ReactNode => value;

interface ConversionNumberProps {
    /** Строка перевода для дробного числа или такого числа, остаток от деления на 100 которого находится в промежутке
     *  от 5 до 20 */
    many: string;
    /** Строка перевода для числа, остаток от деления на 10 которого равен 1 */
    one: string;
    /** Строка перевода для числа, остаток от деления на 10 которого находится в промежутке от 2 до 4 */
    some: string;
    /** Число, которое нужно склонять */
    value: number;
    /** Строка перевода, числа равного 0 */
    zero?: string;
    /** Флаг выводить ли со склоняемым словом число */
    hasValue?: boolean;
    /** Функция замещения заданной подстроки в переводе на заданное значение */
    format?: (value: string) => ReactNode;
    /** Функция обработки числа */
    formatValue?: (value: number) => ReactNode;
}

const Conversion: FC<ConversionNumberProps> = ({
    value,
    one,
    some,
    many,
    zero = '',
    formatValue = defaultFormatValue,
    format = defaultFormat,
    hasValue = true,
}) => {
    if (value === 0) {
        return <>{format(zero)}</>;
    }

    const conversionType = getConversionType({ one, some, many, value });

    return (
        <>
            {hasValue ? (
                <span suppressHydrationWarning>
                    {formatValue(value)}
                    &nbsp;
                    {format(conversionType)}
                </span>
            ) : (
                <span suppressHydrationWarning>{format(conversionType)}</span>
            )}
        </>
    );
};

export default memo(Conversion);
