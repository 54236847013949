import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const ListScaleSmallReducedFalse = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14 8.00001L6 8.00001M14 4.00001L6 4.00001M14 12L6 12M3.33333 8.00001C3.33333 8.3682 3.03486 8.66668 2.66667 8.66668C2.29848 8.66668 2 8.3682 2 8.00001C2 7.63182 2.29848 7.33334 2.66667 7.33334C3.03486 7.33334 3.33333 7.63182 3.33333 8.00001ZM3.33333 4.00001C3.33333 4.3682 3.03486 4.66668 2.66667 4.66668C2.29848 4.66668 2 4.3682 2 4.00001C2 3.63182 2.29848 3.33334 2.66667 3.33334C3.03486 3.33334 3.33333 3.63182 3.33333 4.00001ZM3.33333 12C3.33333 12.3682 3.03486 12.6667 2.66667 12.6667C2.29848 12.6667 2 12.3682 2 12C2 11.6318 2.29848 11.3333 2.66667 11.3333C3.03486 11.3333 3.33333 11.6318 3.33333 12Z"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'List'
);
export default ListScaleSmallReducedFalse;
