import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const LabelKindFlame = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM12.9997 8.85373C13.0226 11.5173 10.882 13.6953 8.2184 13.7182C5.62316 13.7407 3.47552 11.7054 3.3586 9.11265C3.35352 9.02635 3.35352 8.94004 3.35352 8.85373V8.85367C3.35087 7.42632 3.98349 6.07182 5.07972 5.1577C5.27008 5.80261 5.59543 6.39967 6.03418 6.90923C6.44181 6.18565 6.65518 5.36886 6.65352 4.53836L6.65352 4.53847C6.6527 3.64863 6.42168 2.77414 5.98291 2C8.99685 2.5449 11.1972 5.15642 11.2227 8.21912C11.2236 8.26318 11.2219 8.30725 11.2177 8.35112C11.7963 8.16459 12.3326 7.86617 12.7961 7.47281C12.9317 7.92055 13.0003 8.3859 12.9997 8.85373L12.9997 8.85373ZM8.50638 8.09237C8.57153 8.0665 8.64532 8.09835 8.67118 8.16351C8.68762 8.20491 8.68114 8.2519 8.65412 8.28732C8.17366 8.94663 8.11214 9.82249 8.49572 10.5425C8.72125 10.3849 8.89311 10.162 8.98818 9.90381C9.0082 9.83634 9.07913 9.79787 9.1466 9.81789C9.15303 9.8198 9.15931 9.82222 9.16536 9.82512C9.85084 10.1636 10.2628 10.8837 10.2071 11.6462C10.2292 12.7457 9.35586 13.6549 8.25638 13.677C8.22972 13.6775 8.20305 13.6775 8.17638 13.677C7.04978 13.6722 6.14033 12.7551 6.14506 11.6285C6.14512 11.6153 6.1453 11.6021 6.14562 11.5888C6.16334 10.0564 7.0916 8.68156 8.50638 8.09237Z"
                fill="#D92121"
            />
        </svg>
    ),
    'Label'
);
export default LabelKindFlame;
