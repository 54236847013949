import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const BubbleScaleSmallKindSingleAppearanceFilledShapeRound = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 8.00004C1 4.31814 3.98477 1.33337 7.66667 1.33337C11.3486 1.33337 14.3333 4.31814 14.3333 8.00004C14.3333 11.6819 11.3486 14.6667 7.66667 14.6667C6.78112 14.6667 5.93433 14.4937 5.15947 14.179C5.08949 14.1506 5.04908 14.1342 5.01924 14.123L5.01003 14.1196L5.00686 14.12C4.98226 14.1234 4.94873 14.1289 4.8847 14.1396L2.49364 14.5381C2.38655 14.556 2.26674 14.576 2.16269 14.5838C2.04924 14.5924 1.86875 14.5954 1.678 14.5136C1.4422 14.4124 1.25429 14.2245 1.15315 13.9887C1.07134 13.798 1.07432 13.6175 1.08288 13.504C1.09074 13.4 1.11075 13.2802 1.12863 13.1731L1.52714 10.782C1.53781 10.718 1.54333 10.6844 1.54667 10.6598L1.54709 10.6567L1.54368 10.6475C1.53248 10.6176 1.51613 10.5772 1.4877 10.5072C1.17301 9.73238 1 8.88559 1 8.00004ZM5 5.66671C4.63181 5.66671 4.33333 5.96518 4.33333 6.33337C4.33333 6.70156 4.63181 7.00004 5 7.00004H7.66667C8.03486 7.00004 8.33333 6.70156 8.33333 6.33337C8.33333 5.96518 8.03486 5.66671 7.66667 5.66671H5ZM5 8.00004C4.63181 8.00004 4.33333 8.29852 4.33333 8.66671C4.33333 9.0349 4.63181 9.33337 5 9.33337H9.66667C10.0349 9.33337 10.3333 9.0349 10.3333 8.66671C10.3333 8.29852 10.0349 8.00004 9.66667 8.00004H5Z"
                fill="var(--bloko-icon-color, var(--bloko-icon-color-default))"
            />
        </svg>
    ),
    'Bubble'
);
export default BubbleScaleSmallKindSingleAppearanceFilledShapeRound;
